<div fxLayout="column" fxFlex="0 0 100%" id="header" fxLayoutAlign="center center">
	<span id="text-welcome" *ngIf="!(appService.engines$ | async).length">
	  Bienvenue sur votre compte Mediego !
	</span>

  <span>
	  Cette page vous guide pour renseigner vos informations et intégrer la solution Mediego sur votre site web.
	</span>
</div>

<div fxLayout="column" fxFlex="0 0 100%" id="body" fxLayoutAlign="center center">
	<div fxLayout="row" fxFill fxLayoutAlign="center">
    <mat-stepper #stepper orientation="vertical" linear class="mat-elevation-z1" fxFlex="0 0 100%" fxFlex.gt-xs="0 0 75%" fxFlex.gt-md="0 0 50%">
      <mat-step label="Quel site web souhaitez-vous analyser ?" [editable]="false" [completed]="false">
        <div class="step-body">
          <div class="step-intro">
            Vous pourrez ajouter d'autres URLs plus tard le cas échéant.
          </div>
          <form [formGroup]="hostForm">
            <mat-form-field>
              <input matInput placeholder="URL du site web" formControlName="hostName" required>
              <mat-hint>https://www.exemple.com</mat-hint>
              <mat-error *ngIf="hostForm.get('hostName').invalid">URL non valide</mat-error>
            </mat-form-field>
          </form>
        </div>
        <div class="step-footer" fxLayout="row" fxLayoutAlign="end" [ngSwitch]="engineCreationStatus">
          <button *ngSwitchDefault mat-button color="accent" [disabled]="hostForm.invalid" (click)="createEngine()">SUIVANT</button>
          <mat-spinner *ngSwitchCase="'creating'" color="accent" [diameter]="40" [strokeWidth]="4"></mat-spinner>
        </div>
      </mat-step>

      <mat-step label="Mise en place de l'agent">
        <div class="step-body">
          <div class="step-intro">
            Pour pouvoir profiter de l'outil Analytics de Mediego, ajouter cette ligne en bas de chaque page de votre site.
          </div>
          <div class="editor-container" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
            <div fxFlex="0 0 calc(100% - 85px)">
              <input #agentCodeInput type="text" [value]="agentScriptHtml" style="position: absolute; opacity: 0;">
              <pre><code [highlight]="agentScriptHtml" [language]="'xml'"></code></pre>
            </div>
            <button mat-button color="accent" fxFlex="0 0 85px" (click)="copyAgentImportCode()">COPIER</button>
          </div>
        </div>
        <div class="step-footer" fxLayout="row" fxLayoutAlign="end">
          <button mat-button matStepperNext color="accent">SUIVANT</button>
        </div>
      </mat-step>

      <mat-step label="Fin">
        <div class="step-body">
          <div class="step-intro">
            <p>Votre configuration est terminée !</p>
            <p>Vous pourrez observer les premières données environ <strong>1 heure</strong> après avoir effectué l'étape précédente.</p>
            <p>Si vous souhaitez ajouter d'autres sites web ou configurer plus finement votre intégration, rendez-vous dans le menu configuration.</p>
          </div>
          <div class="step-footer" fxLayout="row" fxLayoutAlign="center">
            <button mat-raised-button color="accent" (click)="finishFirstSetup()">TERMINER</button>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</div>
