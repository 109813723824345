import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi }     from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { ApplicationRef, DoBootstrap, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule }                                       from '@angular/platform-browser';
import { BrowserAnimationsModule }                             from '@angular/platform-browser/animations';
import { ServiceWorkerModule }                                 from '@angular/service-worker';

import {
  provideCharts,
  withDefaultRegisterables
} from 'ng2-charts';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';

import { EffectsModule }                                      from '@ngrx/effects';
import {
  FullRouterStateSerializer,
  NavigationActionTiming,
  RouterStateSerializer,
  StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule }          from '@ngrx/store';

import { JwtModule }                          from '@auth0/angular-jwt';
import { TranslateLoader, TranslateModule }   from '@ngx-translate/core';
import { TranslateHttpLoader }                from '@ngx-translate/http-loader';
import { CookieModule }                       from 'ngx-cookie';
import { Highlight, provideHighlightOptions } from 'ngx-highlightjs';
import { QuillModule }                        from 'ngx-quill';

import { AppRoutingModule }    from './app-routing.module';
import { MediegoCommonModule } from './mediego-common-module/mediego-common.module';

import { APP_EFFECTS, APP_REDUCERS, CustomRouterStateSerializer } from './main-module/state';

import { AppService }  from './services/app.service';
import { AuthService } from './services/auth.service';

import { AcceptInviteComponent }   from './main-module/views/accept-invite/accept-invite.component';
import { FirstSetupComponent }     from './main-module/views/first-setup/first-setup.component';
import { ForgotPasswordComponent } from './main-module/views/forgot-password/forgot-password.component';
import { LoginComponent }          from './main-module/views/login/login.component';
import { AppComponent }            from './main-module/views/main/app.component';
import { NoPermissionsComponent }  from './main-module/views/no-permissions/no-permissions.component';
import { PermissionsErrorComponent } from './main-module/views/permissions-error/permissions-error.component';
import { PlatformErrorComponent } from './main-module/views/platform-error/platform-error.component';
import { SignupComponent }         from './main-module/views/signup/signup.component';

import { BackboardComponent } from './main-module/components/backboard/backboard.component';
import { IntercomComponent }        from './main-module/components/intercom/intercom.component';
import { LogoutComponent }          from './main-module/components/logout/logout.component';
import {
  MailerCompatibilityCardComponent
} from './main-module/components/mailer-compatibility-card/mailer-compatibility-card.component';
import { NotificationsCardComponent } from './main-module/components/notifications-card/notifications-card.component';
import { SidenavTabsComponent }     from './main-module/components/sidenav-tabs/sidenav-tabs.component';
import { SnackbarLoadingComponent } from './main-module/components/snackbar-loading/snackbar-loading.component';
import { ToolbarComponent }         from './main-module/components/toolbar/toolbar.component';

import { JWT_MODULE_CONFIG } from './main-module/declarations/mediego-jwt';

import { environment } from '../environments/environment';

import { UnauthorizedInterceptor } from './main-module/interceptors/unauthorized-interceptor';

@NgModule({
  declarations: [
    AppComponent,
    NoPermissionsComponent,
    ToolbarComponent,
    SidenavTabsComponent,
    SignupComponent,
    AcceptInviteComponent,
    LoginComponent,
    FirstSetupComponent,
    PermissionsErrorComponent,
    PlatformErrorComponent,
    LogoutComponent,
    ForgotPasswordComponent,
    IntercomComponent,
    SnackbarLoadingComponent,
    BackboardComponent,
    NotificationsCardComponent,
    MailerCompatibilityCardComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DragDropModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.production
    }),
    JwtModule.forRoot(JWT_MODULE_CONFIG),
    CookieModule.withOptions(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'fr'
    }),
    AppRoutingModule,
    LayoutModule,
    MediegoCommonModule,
    QuillModule.forRoot(),
    StoreModule.forRoot(APP_REDUCERS, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      }
    }),
    EffectsModule.forRoot(APP_EFFECTS),
    // Connects RouterModule with StoreModule
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation
    }),
    OverlayModule,
    Highlight
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    provideHighlightOptions({
      coreLibraryLoader: () => import('highlight.js/lib/core'),
      languages: {
        xml: () => import('highlight.js/lib/languages/xml')
      }
    }),
    provideHttpClient(withInterceptorsFromDi()),
    provideCharts(withDefaultRegisterables())
  ]
})
export class AppModule implements DoBootstrap {

  constructor(
    private authService: AuthService,
    private appService: AppService
  ) {
    registerLocaleData(localeFr);
  }

  async ngDoBootstrap(appRef: ApplicationRef) {
    const loggedIn = this.authService.init();
    if (loggedIn) {
      await this.appService.init();
    }
    const langInit$ = this.appService.initLanguage();
    await langInit$;
    appRef.bootstrap(AppComponent);
  }
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
