import {
  Component,
  ElementRef,
  HostBinding,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
}                                             from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router }                             from '@angular/router';

import { MatSnackBar }                        from '@angular/material/snack-bar';
import { MatStepper }                         from '@angular/material/stepper';

import startCase from 'lodash/startCase';

import { AppService }                         from 'app/services/app.service';

@Component({
    selector: 'app-mediego-first-setup',
    templateUrl: './first-setup.component.html',
    styleUrls: ['./first-setup.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class FirstSetupComponent implements OnInit {
  @HostBinding('class') classes = 'mainContent';

  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  @ViewChild('agentCodeInput', { static: true }) agentCodeInput: ElementRef;

  hostForm: UntypedFormGroup;

  agentScriptHtml = '<script type="text/javascript" src="https://api.mediego.com/agent.js" async="true"></script>';

  engineCreationStatus: undefined | 'creating' | 'success' | 'error';

  // Matches: {0} Complete URL, {1} Protocol, {2} Domain Name, {3} Everything else
  urlRegex = /^(?:(http[s]?):\/{2})?((?!http[s]?)[^:\/\s]+)(\/.*)?/i;

  static generateEngineName(domainName: string): string {
    const domainChunks = domainName.split('.').reverse();
    const [, ...tail] = domainChunks; // all but first element. removes TLD from domain name

    // remove sub-domain if 'www'
    const indexOfWWW = tail.indexOf('www');
    if (indexOfWWW > -1) {
      tail.splice(indexOfWWW, 1);
    }

    const engineName = tail.join('_'); // we use '_' to convert to Start Case

    return startCase(engineName);
  }

  constructor(
    public appService: AppService,
    private router: Router,
    private _fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private _vcr: ViewContainerRef
  ) { }

  ngOnInit() {
    this.hostForm = this._fb.group({
      hostName: ['', Validators.compose([
        Validators.required,
        Validators.pattern(this.urlRegex.source)
      ])]
    });
  }

  copyAgentImportCode() {
    this.agentCodeInput.nativeElement.select();
    this.agentCodeInput.nativeElement.focus();
    document.execCommand('copy');
    this.showSnackBar('Code copié', false, 1000);
  }

  createEngine() {
    const hostName = this.hostForm.get('hostName').value;
    const domainName = this.urlRegex.exec(hostName)[2];

    this.engineCreationStatus = 'creating';
    this.appService.createEngine(
      FirstSetupComponent.generateEngineName(domainName),
      domainName
    ).then(() => {
      this.engineCreationStatus = 'success';
      this.stepper.selected.completed = true;
      this.stepper.next();
    }).catch((error) => {
      console.error(error);
      this.engineCreationStatus = 'error';
      this.showSnackBar(error.error || 'Impossible de créer la configuration', true);
    });
  }

  finishFirstSetup() {
    return this.appService.getSelectedEngine()
      .then((engine) => {
        return this.router.navigate(['/'], { queryParams: { engineId: engine.id } });
      });
  }

  showSnackBar(message: string, error?: boolean, duration?: number) {
    this.snackBar.open(
      message,
      undefined,
      {
        panelClass: ['mediego-snack-bar', error ? 'warn' : 'accent'],
        duration: duration || 3000,
        viewContainerRef: this._vcr
      }
    );
  }
}
