import { Injectable }                                                                from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { firstValueFrom } from 'rxjs';

import { AuthState }                                                                 from '../state/reducers/auth.reducer';

import { AuthService }                                                               from '../../services/auth.service';

import { AuthUtils }                                                                 from '../utils/auth.utils';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const authState: AuthState = await firstValueFrom(this.authService.authState$);
    if (authState.isLoggedIn) {
      if (authState.isDemoUser) {
        if (!AuthUtils.isDemoExpired(authState.demoExpiryDate)) {
          return true;
        } else {
          this.authService.logout();
          return this.router.parseUrl('/signup');
        }
      } else {
        return true;
      }
    } else {
      // Store the attempted URL for redirecting
      this.authService.redirectUrl = state.url;

      // Navigate to the login page
      return this.router.parseUrl('/login');
    }
  }

}


@Injectable()
export class NotAuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(): Promise<boolean | UrlTree> {
    const isLoggedIn = await firstValueFrom(this.authService.isLoggedIn$);

    if (!isLoggedIn) {
      return true;
    } else {
      // Navigate to the main page
      return this.router.parseUrl('/');
    }
  }
}
