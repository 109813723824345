import {
  ApplicationRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SwUpdate } from '@angular/service-worker';
import { MatDialog }                   from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatDrawer }                   from '@angular/material/sidenav';
import { Store } from '@ngrx/store';
import { concat, EMPTY, firstValueFrom, interval, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  first,
  map,
  startWith,
  switchMap,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import deburr from 'lodash/deburr';

import { AppState }                 from '../../state';
import { ToggleAdminDisplayAction } from '../../state/actions/auth.actions';
import { UserSelectedEngineAction } from '../../state/actions/engines.actions';

import { SegmentsService } from '../../../services/segments.service';
import { TemplaterService } from '../../../templater-module/services/templater.service';
import { AppService }      from 'app/services/app.service';
import { AuthService }     from 'app/services/auth.service';
import { LayoutService }   from 'app/services/layout.service';

import { SnackbarLoadingComponent } from '../../components/snackbar-loading/snackbar-loading.component';

import { Engine }        from 'app/main-module/declarations/engine';
import { GlobalError }   from 'app/main-module/declarations/global-error';
import { LoadingStatus } from '../../declarations/loading-status';

@Component({
    selector: 'app-mediego-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [SegmentsService],
    standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('class') classes = 'mat-typography';
  @ViewChild('addEngineDialogTemplateRef', { static: true }) addEngineDialogTemplateRef;
  @ViewChild('sidenav', { static: true }) sidebar: MatDrawer;

  filteredEngines$: Observable<Engine[]>;
  selectedEngineFormControl = new UntypedFormControl();

  private globalLoadingSnackBarRef: MatSnackBarRef<SnackbarLoadingComponent>;
  private globalErrorSnackBarRef: MatSnackBarRef<SnackbarLoadingComponent>;

  private shakingInterval;
  private unsubscribe$ = new Subject<void>();

  get engines$(): Observable<Engine[]> {
    return this.appService.engines$;
  }

  get selectedEngine$(): Observable<Engine | undefined> {
    return this.appService.selectedEngine$;
  }

  constructor(
    private appRef: ApplicationRef,
    private appUpdatesService: SwUpdate,
    private store: Store<AppState>,
    public authService: AuthService,
    public appService: AppService,
    private translate: TranslateService,
    public layoutService: LayoutService,
    // private router: Router,
    private snackBar: MatSnackBar,
    public templaterService: TemplaterService,
    public dialog: MatDialog,
    private vcr: ViewContainerRef
  ) {}

  async ngOnInit() {

    this.appService.onGlobalError
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((globalError: GlobalError) => {
        this.showGlobalErrorSnackBar(
          globalError.error.message,
          globalError.options.snackBarDuration,
          globalError.options.snackBarActionTitle,
          globalError.options.viewContainerRef
        );
      });

    this.appService.onClearGlobalError
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.hideGlobalErrorSnackbar();
      });

    this.appService.loadingStatus$
      .pipe(
        debounceTime(150),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((status: LoadingStatus) => {
        switch (status) {
          case 'loading':
            this.showGlobalLoadingSnackBar();
            break;
          case 'ready':
            this.hideGlobalLoadingSnackbar();
            break;
          case 'error':
          default:
            // do nothing
            break;
        }
      });

    // this.router.events
    //   .pipe(takeUntil(this.unsubscribe$))
    //   .subscribe((event: RouterEvent) => {
    //     if (event instanceof RouteConfigLoadStart) {
    //       this.appService.addLoadingEvent(`[LAZY_MODULE] ${event.route.path}`);
    //     } else if (event instanceof RouteConfigLoadEnd) {
    //       this.appService.removeLoadingEvent(`[LAZY_MODULE] ${event.route.path}`);
    //     }
    //   });

    this.appService.selectedEngine$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((engine: Engine) => {
        this.selectedEngineFormControl.setValue(engine, { emitEvent: false });
      });

    this.templaterService.modified$$.asObservable().pipe(
      map((templateUnsaved) => {
        if (templateUnsaved) this.selectedEngineFormControl.disable({ emitEvent: false });
        else this.selectedEngineFormControl.enable({ emitEvent: false });
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe();

    this.appService.engines$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((engines: Engine[]) => {
        if (engines && engines.length > 1) {
          this.selectedEngineFormControl.enable({ emitEvent: false });
        } else {
          this.selectedEngineFormControl.disable({ emitEvent: false });
        }
      });

    const input$ = this.selectedEngineFormControl
      .valueChanges
      .pipe(
        debounceTime(200),
        startWith(null)
      );

    // autocomplete filter for engines
    this.filteredEngines$ = this.authService.isAdmin$
      .pipe(
        switchMap((isAdmin: boolean) =>
          isAdmin ? input$ : EMPTY // if not admin, switch to empty observable that never emits
        ),
        withLatestFrom(this.appService.engines$),
        map((data: [string | Engine, Engine[]]) => {
          const searchTerm: string | Engine = data[0];
          const engines: Engine[] = data[1];

          if (searchTerm === null || typeof searchTerm !== 'string' || searchTerm === '') {
            return engines;
          }

          return engines.filter((engine: Engine) =>
            deburr(searchTerm as string)
              .toLowerCase()
              .split(' ')
              .every((chunk) =>
                deburr(engine.displayName)
                  .toLowerCase()
                  .includes(chunk)
              )
          );
        })
      );

    if (this.appUpdatesService.isEnabled) {
      this.appUpdatesService.versionUpdates.subscribe((event) => {
        switch (event.type) {
          case 'VERSION_DETECTED':
            console.warn('An update is available:', event.version);
            this.promptUserForAppUpdate()
              .then(() => this.appUpdatesService.activateUpdate())
              .then(() => document.location.reload());
            break;
          case 'VERSION_READY':
            console.warn('Current version is:', event.currentVersion);
            console.warn('New version is:', event.latestVersion);
            break;
        }
      });

      // Allow the app to stabilize first, before starting polling for updates with `interval()`.
      const appIsStable$ = this.appRef.isStable.pipe(first((__) => __ === true));
      const everyHour$ = interval(60 * 60 * 1000); // in ms
      const everyHourOnceAppIsStable$ = concat(appIsStable$, everyHour$);

      everyHourOnceAppIsStable$.subscribe(async () => {
        try {
          const updateFound = await this.appUpdatesService.checkForUpdate();
          console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
        } catch (err) {
          console.error('Failed to check for updates:', err);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private promptUserForAppUpdate(): Promise<void> {
    const snackBarRef = this.snackBar.open(
      this.translate.instant('MAIN.APP.SNACKBAR.NEW_VERSION.MESSAGE'),
      this.translate.instant('MAIN.APP.SNACKBAR.NEW_VERSION.ACTION'),
      {
        panelClass: ['mediego-snack-bar', 'button-accent-2', 'app-update']
      }
    );

    const shakeUpdateSnackbar = () => {
      const snackbar = document.querySelector('.mediego-snack-bar.app-update');
      if (snackbar) {
        snackbar.classList.remove('shakeit');
        setTimeout(() => {
          const snackbar = document.querySelector('.mediego-snack-bar.app-update');
          if (snackbar) snackbar.classList.add('shakeit');
          else if (this.shakingInterval) clearInterval(this.shakingInterval);
        }, 200);
      } else if (this.shakingInterval) {
        clearInterval(this.shakingInterval);
      }
    };

    shakeUpdateSnackbar();

    this.shakingInterval = setInterval(shakeUpdateSnackbar, 2 * 60000); // every 2 minutes, shake snackbar

    return firstValueFrom(snackBarRef.onAction());
  }

  getEngineName(engine?: Engine): string {
    return engine ? engine.displayName || engine.id.toString() : '';
  }

  async closeSidenavIfApplicable() {
    return await this.sidebar.close();
  }

  private showGlobalErrorSnackBar(error_message: string, duration: number = 3000, actionTitle?: string, viewContainerRef?: ViewContainerRef) {
    this.globalErrorSnackBarRef = this.snackBar.open(
      error_message,
      actionTitle,
      {
        panelClass: ['mediego-snack-bar', 'warn'],
        duration: actionTitle ? undefined : duration,
        viewContainerRef
      }
    );

    this.globalErrorSnackBarRef.onAction()
      .pipe(
        map(() => {
          this.appService.onSnackBarAction.next(undefined);
          this.appService.onSnackBarAction.complete(); // complete will terminate further retries
        })
      )
      .subscribe();

    this.globalErrorSnackBarRef.afterDismissed()
      .pipe(map(() => {
        this.appService.onSnackBarDismissed.next(undefined);
        this.appService.onSnackBarDismissed.complete(); // complete will terminate further retries
      }))
      .subscribe();
  }

  private hideGlobalErrorSnackbar() {
    if (this.globalErrorSnackBarRef) {
      this.globalErrorSnackBarRef.dismiss();
      this.globalErrorSnackBarRef = undefined;
    }
  }

  private showGlobalLoadingSnackBar() {
    this.globalLoadingSnackBarRef = this.snackBar.openFromComponent(
      SnackbarLoadingComponent,
      {
        panelClass: ['mediego-snack-bar', 'loading'],
        duration: undefined,
        viewContainerRef: this.vcr
      }
    );
  }

  private hideGlobalLoadingSnackbar() {
    if (this.globalLoadingSnackBarRef) {
      this.globalLoadingSnackBarRef.dismiss();
      this.globalLoadingSnackBarRef = undefined;
    }
  }

  openAddEngineDialog(): void {
    const dialogRef = this.dialog.open(
      this.addEngineDialogTemplateRef,
      {
        panelClass: 'mediego-dialog'
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.addEngine(result.name, result.domainName);
      }
    });
  }
  private addEngine(displayName: string, domainName: string) {
    this.appService.createEngine(displayName, domainName)
      .then(() => {
        this.snackBar.open(this.translate.instant('MAIN.APP.SNACKBAR.ENGINE_ADDED.SUCCESS'), null, { duration: 2000 });
      });
  }

  async changeSelectedEngine(engine: Engine) {
    this.store.dispatch(new UserSelectedEngineAction({ engine }));
  }

  toggleAdminDisplay(displayAdmin: boolean) {
    this.store.dispatch(new ToggleAdminDisplayAction({ displayAdmin }));
  }

}

