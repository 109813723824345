import { Component, HostBinding, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router }         from '@angular/router';

import { MatSnackBar }                    from '@angular/material/snack-bar';

import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { AppService }                         from '../../../services/app.service';
import { AuthService }                    from 'app/services/auth.service';


@Component({
    selector: 'app-mediego-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent implements OnInit, OnDestroy {
  @HostBinding('class') classes = 'mainContent fullScreenMobile';

  isLoading: boolean;

  loginForm: UntypedFormGroup;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private authService: AuthService,
    private appService: AppService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private _fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private _vcr: ViewContainerRef
  ) { }

  ngOnInit() {
    this.isLoading = false;
    this.loginForm = this._fb.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.email
      ])],
      password: ['', Validators.required]
    });

    const token = this.route.snapshot.paramMap.get('token');
    if (token) {
      console.warn('demo accounts are no longer supported');
    }

    // For ease-of-use, we allow the email to be passed as a query parameter
    // If browser enables atob, decrypt the email, else email will be provided without encryption
    this.route.queryParams.pipe(
      map((params) => {
        if (params?.email && this.loginForm?.get('email')) {
          const emailDecrypted = window.hasOwnProperty('atob') ? window.atob(params.email) : params.email;
          this.loginForm.get('email').setValue(emailDecrypted);
        }
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSubmit() {
    if (this.loginForm.valid) {
      this.isLoading = true;
      this.authService
        .login(
          this.loginForm.get('email').value,
          this.loginForm.get('password').value
        )
        .then(() => this.appService.loadEngines())
        .then(() => {
          this.isLoading = false;

          // Redirect the user
          return this.router.navigateByUrl(this.authService.redirectUrl);
        }).catch((error) => {
          console.error(error);
          const error_message: string = error.status === 403 ?
            this.translate.instant('MAIN.LOGIN.ERROR.WRONG_CREDENTIALS') : undefined;
          this.isLoading = false;
          this.showSnackBarError(error_message);
        });
    }
  }

  showSnackBarError(error_message?: string) {
    this.snackBar.open(
      error_message || this.translate.instant('MAIN.LOGIN.ERROR.CONNECTION.FAILED'),
      undefined,
      {
        panelClass: ['mediego-snack-bar', 'warn'],
        duration: 2000,
        viewContainerRef: this._vcr
      }
    );
  }

}
