import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Route } from '@angular/router';

import { Store } from '@ngrx/store';

import { combineLatest, firstValueFrom, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { AppState } from '../../state';
import { selectIsAdmin } from '../../state/selectors/auth-selectors';
import {
  selectNewsletterUserPermissions,
  selectSegmentUserPermissions,
  selectSelectedEngine
} from '../../state/selectors/engines-selectors';
import {
  selectBreadCrumbs,
  selectCurrentRouteDocHref,
  selectParentPath,
  selectSelectedSegment,
  selectSelectedSource,
  selectTabCrumb
} from '../../state/selectors/router-selectors';

import { ContactsService } from '../../../crm-module/services/contacts.service';
import { AppService } from '../../../services/app.service';
import { AuthService } from '../../../services/auth.service';
import { LayoutService } from '../../../services/layout.service';
import { NotificationsService } from '../../../services/notifications.service';

import { ContactList } from '../../../configuration-module/providers/mailers.provider';

import {
  ALL_EMAILING_SOURCES,
  ALL_PERSONALISATION_SOURCES,
  NewsletterSource,
  Source
} from '../../../mediego-common-module/declarations/source';
import { Segment } from '../../../segments-module/declarations/segment';
import { Engine, NewsletterPermissions, SegmentPermissions } from '../../declarations/engine';
import { AVAILABLE_LANGUAGES, Language, LANGUAGE_DISPLAY_NAMES } from '../../declarations/lang';

interface MediegoRoute extends Route {
  label: string;
  icon: string;
  visible: boolean;
  intercomTag?: string;
}

interface Breadcrumb {
  label: string;
  engineRedirect?: boolean;
  newslettersRedirect?: boolean;
  newslettersRedirectFilter?: string;
}

@Component({
    selector: 'app-mediego-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ToolbarComponent implements OnInit, OnDestroy {
  displayedBreadcrumbs$: Observable<Breadcrumb[]>;
  tabRoutes: MediegoRoute[];

  @Output() menuButtonClick = new EventEmitter<void>();

  applyShadow: boolean = false;

  notificationsOpened: boolean = false;

  availableLanguages: Language[] = Object.values(AVAILABLE_LANGUAGES);

  get selectedContactList$(): Observable<ContactList> {
    return this.contactsService.selectedContactsList$;
  }

  get selectedLanguage$(): Observable<Language> {
    return this.appService.selectedLanguage$.pipe(distinctUntilChanged());
  }

  get docHref$(): Observable<string> {
    return this.store.select(selectCurrentRouteDocHref).pipe(distinctUntilChanged());
  }

  get pendingNotifications$(): Observable<boolean> {
    return this.notificationsService.hasPendingNotifications$();
  }

  private unsubscribe$ = new Subject<void>();

  constructor(
    public authService: AuthService,
    public layoutService: LayoutService,
    private notificationsService: NotificationsService,
    private appService: AppService,
    private contactsService: ContactsService,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initBreadCrumbs();
    this.initTabs();

    this.layoutService
      .scrollOffset$
      .pipe(
        distinctUntilChanged(),
        map((__) => __ > 0),
        distinctUntilChanged()
      )
      .subscribe((applyShadow) => {
        this.applyShadow = applyShadow;
        this.cdr.detectChanges(); // FIXME: this is a hack. find out why angular isn't automatically detecting the change
      });

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onMenuButtonClick() {
    this.menuButtonClick.emit();
  }

  langDisplayName(lang: Language): string {
    return LANGUAGE_DISPLAY_NAMES[lang];
  }

  setLanguage(lang: Language) {
    this.appService.setLanguage(lang);
  }

  private initBreadCrumbs(): void {
    const info$ = combineLatest(
      [
        this.store.select(selectSelectedEngine),
        this.store.select(selectSelectedSegment),
        this.appService.selectedSource$
      ]
    ).pipe(
      switchMap(async([engine, segment, source]: [Engine, Segment, Source]) => {

        const baseCrumbs$ = this.store.select(selectBreadCrumbs).pipe(
          map(crumbs => crumbs.map(crumb => ({
            label: crumb,
            newslettersRedirect: crumb === 'MAIN.APP.SIDEBAR.NEWSLETTERS.TITLE'
          })))
        );
        const baseCrumbs: Breadcrumb[] = await firstValueFrom(baseCrumbs$);

        let folders: string[] = [];
        let element = null;
        const tab = await firstValueFrom(this.store.select(selectTabCrumb));

        if (source) {
          element = source.displayName;
          folders = source.collection;
        } else if (segment) {
          element = segment.name;
        }

        return { engine, baseCrumbs, folders, element, tab };
      })
    );

    this.displayedBreadcrumbs$ =
      combineLatest(
        [
          info$,
          this.layoutService.isMobile
        ]
      ).pipe(
        // debounceTime(100),
        map((data) => {
          const { engine, baseCrumbs, folders, element, tab } = data[0];
          const isMobile = data[1];

          const isNewsletterFolder = !!(baseCrumbs?.length && baseCrumbs[0].newslettersRedirect);

          let displayedBreadcrumbs: Breadcrumb[] = [];

          if (isMobile) {
            if (element) { // if there is a element
              displayedBreadcrumbs.push({ label: element }); // only display element
            } else { // if not
              displayedBreadcrumbs = baseCrumbs; // only display base crumbs
            }
          } else {
            if (engine) {
              displayedBreadcrumbs.push({ label: engine.displayName, engineRedirect: true }); // start with engine name
            }

            displayedBreadcrumbs = displayedBreadcrumbs.concat(baseCrumbs); // add all base crumbs
            displayedBreadcrumbs = displayedBreadcrumbs.concat(folders.map((folder, index) => {
              if (isNewsletterFolder) {
                return { label: folder, newslettersRedirect: true, newslettersRedirectFilter: folders.slice(0, index + 1).join('/') };
              } else {
                return { label: folder };
              }
            })); // add all folder crumbs

            if (element) displayedBreadcrumbs.push({ label: element }); // add element in case there is one
            if (tab) displayedBreadcrumbs.push({ label: tab }); // add tab in case there is one
          }

          return displayedBreadcrumbs.filter((__) => __?.label); // remove undefined values
        }),
        startWith([])
      );
  }

  private initTabs(): void {

    combineLatest(
      [
        this.store.select(selectIsAdmin),
        this.store.select(selectSelectedEngine),
        this.store.select(selectSelectedSource),
        this.store.select(selectSelectedSegment),
        this.store.select(selectParentPath)
      ]
    ).pipe(
      switchMap(async([isAdmin, engine, selectedSource, selectedSegment, parentPath]) => {
        let newsletterPermissions: NewsletterPermissions;
        if (selectedSource) newsletterPermissions = await firstValueFrom(this.store.select(selectNewsletterUserPermissions, { sourceId: selectedSource.id }));

        let segmentPermissions: SegmentPermissions;
        if (selectedSegment) segmentPermissions = await firstValueFrom(this.store.select(selectSegmentUserPermissions, { segmentId: selectedSegment.id }));

        return { parentPath, engine, selectedSource, newsletterPermissions, selectedSegment, segmentPermissions, isAdmin };
      }),
      map(({ parentPath, engine, selectedSource, newsletterPermissions, selectedSegment, segmentPermissions, isAdmin }) => {
        if (engine === undefined) {
          return [];
        }

        switch (parentPath) {
          case 'newsletters':
            if (selectedSource === undefined || selectedSource === ALL_EMAILING_SOURCES) { // sourceIsAllSources
              return [];
            }
            return [
              {
                path: `/newsletters/${ selectedSource.id }/edit-template`,
                label: 'MAIN.APP.TOOLBAR.NEWSLETTERS.TEMPLATE',
                icon: 'view_quilt',
                visible: newsletterPermissions.editTemplate && (selectedSource as NewsletterSource).enableTemplateEditor,
                intercomTag: 'tab_news_template'
              },
              {
                path: `/newsletters/${ selectedSource.id }/edit-content`,
                label: 'MAIN.APP.TOOLBAR.NEWSLETTERS.EDITORIAL',
                icon: 'edit',
                visible: newsletterPermissions.editContent,
                intercomTag: 'tab_news_editorial'
              },
              {
                path: `/newsletters/${ selectedSource.id }/edit-variables`,
                label: 'MAIN.APP.TOOLBAR.NEWSLETTERS.VARIABLES',
                icon: 'code',
                visible: newsletterPermissions.editVariables,
                intercomTag: 'tab_news_variables'
              },
              {
                path: `/newsletters/${ selectedSource.id }/sending-settings`,
                label: 'MAIN.APP.TOOLBAR.NEWSLETTERS.CAMPAIGNS',
                icon: 'send',
                visible: newsletterPermissions.editCampaigns,
                intercomTag: 'tab_news_dispatch'
              },
              {
                path: `/newsletters/${ selectedSource.id }/stats`,
                label: 'MAIN.APP.TOOLBAR.STATS',
                icon: 'equalizer',
                visible: newsletterPermissions.statistics,
                intercomTag: 'tab_news_stats'
              },
              {
                path: `/newsletters/${ selectedSource.id }/setup`,
                label: 'MAIN.APP.TOOLBAR.CONFIGURATION',
                icon: 'settings_outline',
                visible: newsletterPermissions.configuration,
                intercomTag: 'tab_news_config'
              }
            ];
          case 'web-personalisation':
            if (selectedSource === undefined || selectedSource === ALL_PERSONALISATION_SOURCES) { // sourceIsAllSources
              return [];
            }
            return [
              {
                path: `/web-personalisation/${ selectedSource.id }/stats`,
                label: 'MAIN.APP.TOOLBAR.STATS',
                icon: 'equalizer',
                visible: true,
                intercomTag: 'tab_news_stats'
              },
              {
                path: `/web-personalisation/${ selectedSource.id }/setup`,
                label: 'MAIN.APP.TOOLBAR.CONFIGURATION',
                icon: 'settings_outline',
                visible: true,
                intercomTag: 'tab_web_config'
              }
            ];
          case 'segments':
            if (selectedSegment === undefined) { // isAnalytics
              return [];
            }
            return [
              {
                path: `/segments/${ selectedSegment.id }/stats`,
                label: 'MAIN.APP.TOOLBAR.STATS',
                icon: 'equalizer',
                visible: segmentPermissions.statistics,
                intercomTag: 'tab_segment_stats'
              },
              {
                path: `/segments/${ selectedSegment.id }/actions`,
                label: 'MAIN.APP.TOOLBAR.SEGMENTS.ACTIONS',
                icon: 'touch_app',
                visible: segmentPermissions.createActions,
                intercomTag: 'tab_segment_actions'
              },
              {
                path: `/segments/${ selectedSegment.id }/edit`,
                label: 'MAIN.APP.TOOLBAR.CONFIGURATION',
                icon: 'settings',
                visible: segmentPermissions.configuration,
                intercomTag: 'tab_segment_config'
              }
            ];
          default:
            return [];
        }
      }),
      map((routes: MediegoRoute[]) => {
        this.tabRoutes = routes;
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe();
  }

  trackRouteFn(route: MediegoRoute) {
    return route.path;
  }
}
