/// <reference types="@angular/localize" />
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import dayjs             from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import LocalizedFormat   from 'dayjs/plugin/localizedFormat';
import relativeTime      from 'dayjs/plugin/relativeTime';
import weekOfYear        from 'dayjs/plugin/weekOfYear';

import { AppModule }   from './app/app.module';

import { environment } from './environments/environment';

dayjs.extend(customParseFormat);
dayjs.extend(LocalizedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(relativeTime);

if (environment.production) {
  enableProdMode();
}
platformBrowserDynamic().bootstrapModule(AppModule);
