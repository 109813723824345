import { Component, OnInit } from '@angular/core';
import { Router }                                  from '@angular/router';

import { AuthService }                             from 'app/services/auth.service';

@Component({
    selector: 'app-mediego-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
    standalone: false
})
export class LogoutComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

}
