<mat-card appearance="outlined" fxFlex="100%" fxFlex.gt-xs="450px">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate" color="accent"></mat-progress-bar>

  <div id="header" fxLayout="column" fxLayoutAlign="start center">
    <mat-icon color="accent">security</mat-icon>
    <h1>{{ 'MAIN.LOGIN.TITLE.A' | translate }}</h1>
    <h3>{{ 'MAIN.LOGIN.TITLE.B' | translate }}</h3>
  </div>

  <mat-card-content>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" fxLayout="column" fxLayoutGap="5px">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'COMMON.FORM.EMAIL' | translate }}</mat-label>
        <input matInput formControlName="email" required autofocus tabindex="1" autocomplete="username">
        <mat-error *ngIf="loginForm.get('email').invalid">{{ 'COMMON.REGEX.EMAIL.INVALID' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'COMMON.FORM.PASSWORD' | translate }}</mat-label>
        <input matInput type="password" formControlName="password" required tabindex="2" autocomplete="current-password">
        <mat-error *ngIf="loginForm.get('password').invalid">{{ 'COMMON.REGEX.PASSWORD.REQUIRED' | translate }}</mat-error>
      </mat-form-field>

      <div id="buttons-row" fxFlex="row" fxLayoutAlign="end">
        <button mat-raised-button color="accent" [disabled]="isLoading || loginForm.invalid" type="submit" tabindex="3">
          {{ 'SHARED.BUTTONS.NEXT' | translate }}
        </button>
      </div>

    </form>
  </mat-card-content>
  <mat-card-footer>
    <a routerLink="/password-reset" tabindex="5">{{ 'MAIN.LOGIN.FORGOT_PASSWORD.BUTTON' | translate }}</a>
  </mat-card-footer>
</mat-card>
