import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router }        from '@angular/router';

import { AuthService }   from '../../../services/auth.service';
import { LayoutService } from '../../../services/layout.service';

@Component({
    selector: 'app-mediego-intercom',
    templateUrl: './intercom.component.html',
    styleUrls: ['./intercom.component.scss'],
    standalone: false
})
export class IntercomComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private authService: AuthService,
    public layoutService: LayoutService
  ) { }

  async ngOnInit(): Promise<void> {

    // TODO mask intercom until user clicks on button
    // (as we're not using navigation tracking)

    const email = await this.authService.getEmail();
    (window as any).Intercom('boot', {
      app_id: 'qsgd92gx',
      api_base: 'https://api-iam.intercom.io',
      hide_default_launcher: true,
      email
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        (window as any).Intercom('update');
      }
    });
  }

  ngOnDestroy(): void {
    (window as any).Intercom('shutdown');
  }

  open() {
    (window as any).Intercom('show');
  }

}
