import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
}                 from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { CdkDragDrop, CdkDragEnd, CdkDragEnter, CdkDragExit, CdkDragStart } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';

import { Store } from '@ngrx/store';

import { BehaviorSubject, combineLatest, firstValueFrom, Observable, Subject } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { AppState }                                          from '../../state';
import { selectSelectedTabInfo }                             from '../../state/selectors/router-selectors';
import {
  AddEmailSourceFolder,
  InsertNewsletterPermissionsInSelectedEngineAction
} from '../../state/actions/engines.actions';

import { AppService }      from '../../../services/app.service';
import { AuthService }     from '../../../services/auth.service';
import { SegmentsService } from '../../../services/segments.service';
import { SourceService }   from '../../../services/source.service';

import { OrderByKeyPipe } from '../../../mediego-common-module/pipes/order-by-key.pipe';

import {
  ALL_EMAILING_HISTORY,
  ALL_EMAILING_SOURCES,
  ALL_PERSONALISATION_SOURCES,
  isNewsletter
} from 'app/mediego-common-module/declarations/source';
import { NewsletterSource, Source }                          from '../../../mediego-common-module/declarations/source';
import { Segment }                                           from '../../../segments-module/declarations/segment';
import { Engine, NewsletterPermissions, SegmentPermissions } from '../../declarations/engine';
import { Folder }                                            from '../../declarations/folder';

import { AuthUtils } from '../../utils/auth.utils';

@Component({
    selector: 'app-mediego-sidenav-tabs',
    templateUrl: './sidenav-tabs.component.html',
    styleUrls: ['./sidenav-tabs.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SidenavTabsComponent implements OnInit, OnDestroy {
  @ViewChild('addNewsletterDialogTemplateRef', { static: true }) addNewsletterDialogTemplateRef;
  @ViewChild('addWebSourceDialogTemplateRef', { static: true }) addWebSourceDialogTemplateRef;
  @ViewChild('moveSourceDialogTemplateRef', { static: true }) moveSourceDialogTemplateRef;

  @Output() shouldCloseSidebar = new EventEmitter<void>();



  addWebSourceForm: UntypedFormGroup;
  addNewsletterForm: UntypedFormGroup;
  engineListForDuplication: Array<Array<string | number>> = []; // array of pair [engine name, engine id]
  newsletterListForDuplication: string[][] = []; // array of pair [newsletter name, newsletter id]
  duplicateEmailSourceSelectShown: boolean = false;


  get isEngineAdmin$(): Observable<boolean> {
    return this.appService.isEngineAdmin$();
  }

  get isAdmin$(): Observable<boolean> {
    return this.authService.isAdmin$;
  }

  canMoveFolder: boolean = false;

  newsletterDragged: Source = null;
  folderHovered: Folder = null;
  isDraggingMode: boolean = false;

  newEmptyFolder: Folder = {
    name: '',
    folders: [],
    items: []
  };


  ALL_EMAILING_SOURCES = ALL_EMAILING_SOURCES;
  ALL_EMAILING_HISTORY = ALL_EMAILING_HISTORY;
  ALL_PERSONALISATION_SOURCES = ALL_PERSONALISATION_SOURCES;

  private sidenavHomeTab$: Observable<Folder>;

  sidenavTabs$$ = new BehaviorSubject<Folder[]>([]);
  selectedTabIndex$$ = new BehaviorSubject<number>(0);
  get selectedTabIndex(): number {
    return this.selectedTabIndex$$.getValue();
  }

  allTabs$: Observable<Folder[]>;

  get nbOfTabs(): number {
    return this.sidenavTabs$$.getValue().length;
  }
  get selectedTab(): Folder {
    return this.sidenavTabs$$.getValue()[this.selectedTabIndex - 1];
  }
  get previousTab(): Folder {
    return this.sidenavTabs$$.getValue()[this.selectedTabIndex];
  }

  get selectedEngine$(): Observable<Engine | undefined> {
    return this.appService.selectedEngine$;
  }

  private unsubscribe$ = new Subject<void>();

  constructor(
    private appService: AppService,
    private translate: TranslateService,
    private authService: AuthService,
    private sourceService: SourceService,
    private segmentsService: SegmentsService,
    private store: Store<AppState>,
    private _fb: UntypedFormBuilder,
    public dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit() {
    this.appService.selectedEngine$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.removeAllExtraTabs();
      });

    this.sidenavHomeTab$ = combineLatest(
      [
          this.sourceService.webSourcesFolderOfSelectedEngine$,
          this.sourceService.newsletterSourcesFolderOfSelectedEngine$,
          this.segmentsService.segmentsFolderOfSelectedEngine$
        ]
    ).pipe(
      withLatestFrom(
        this.appService.selectedEngine$,
        this.authService.isDemoUser$,
        this.authService.isAdmin$
      ),
      map(([[webSourcesFolder, newsletterSourcesFolder, segmentsFolder], selectedEngine, isDemoUser, isAdmin]) => {
        const hasEngine = isDemoUser === false && selectedEngine && selectedEngine.id !== -1;

        const hasAtLeastOnePermissionFrom = (permissions: string[]) => permissions.find(permission => !!selectedEngine.userPermissions.configurations[permission]);

        const hasAtLeastOnePermissionOn = (resource: 'segments' | 'newsletters'): boolean => {
          const target: any[] = selectedEngine.userPermissions[resource];
          return target.find((perms: (NewsletterPermissions | SegmentPermissions)) => !!perms && typeof perms === 'object'
            && !!Object.values(perms).find((perm: boolean | undefined) => perm === true))
        };

        const configLinkRelevant = hasEngine && hasAtLeastOnePermissionFrom(['integration', 'accessManagement', 'mailers', 'billing']);
        const contentLinkRelevant = hasEngine && hasAtLeastOnePermissionFrom(['itemSets']);
        const crmLinkRelevant = (selectedEngine?.id === 42 || selectedEngine?.id === 21042) && isAdmin;

        const newslettersLinkRelevant = hasEngine &&
          (selectedEngine?.userPermissions?.createNewsletters // can create newsletters
          || hasAtLeastOnePermissionOn('newsletters')); // or has at least one newsletter with at least one permission (making link relevant)

        // As web sources are legacy, only showing it to clients already having some
        const webSourcesLinkRelevant = hasEngine && (webSourcesFolder?.items?.length || webSourcesFolder?.folders?.length);

        // same for segments
        const segmentsLinkRelevant = hasEngine &&
          (selectedEngine?.userPermissions?.createSegments
            || hasAtLeastOnePermissionOn('segments'));

        const homeFolders: Folder[] = [];

        if (webSourcesLinkRelevant) {
          homeFolders.push({
            ...webSourcesFolder,
            icon: 'language',
            intercomTarget: 'web_personalisation_panel',
            priority: 2
          });
        }

        if (newslettersLinkRelevant) {
          homeFolders.push({
            ...newsletterSourcesFolder,
            icon: 'email',
            intercomTarget: 'email_panel',
            priority: 1
          });
        }

        if (segmentsLinkRelevant) {
          homeFolders.push({
            ...segmentsFolder,
            icon: 'group_work',
            intercomTarget: 'segments_panel',
            priority: 6
          });
        }

        if (crmLinkRelevant) {
          homeFolders.push({
            name: 'MAIN.APP.SIDEBAR.CRM.TITLE',
            icon: 'import_contacts',
            folders: [],
            items: [],
            priority: 5
          });
        }

        if (contentLinkRelevant) {
          homeFolders.push({
            name: 'MAIN.APP.SIDEBAR.CONTENTS.TITLE',
            icon: 'dashboard',
            folders: [],
            items: [],
            priority: 3
          });
        }

        if (configLinkRelevant) {
          homeFolders.push({
            name: 'MAIN.APP.SIDEBAR.CONFIGURATION.TITLE',
            icon: 'settings',
            folders: [],
            items: [],
            priority: 8
          });
        }

        return {
          name: 'SHARED.BUTTONS.HOME',
          folders: homeFolders,
          items: [],
          priority: 0
        };
      })
    );

    this.allTabs$ = combineLatest(
      [
        this.sidenavHomeTab$,
        this.sidenavTabs$$
      ]
    ).pipe(
      map(([homeTab, otherTabs]) =>
        [homeTab, ...otherTabs]
      )
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async selectFolder(folder: Folder) {
    const currentTabs = this.sidenavTabs$$.getValue();
    this.sidenavTabs$$.next([...currentTabs, folder]);
    this.selectedTabIndex$$.next(this.selectedTabIndex + 1);

    // previewing newsletters homepage
    if (folder && folder.name === 'MAIN.APP.SIDEBAR.NEWSLETTERS.TITLE') {
      await this.router.navigate([AuthUtils.getDefaultRouteOfNewsletter(null, null)], { queryParamsHandling: 'preserve' });
      // not closing sidebar on purpose
    }

    // previewing contacts lists
    if (folder?.name === 'MAIN.APP.SIDEBAR.CRM.TITLE') {
      this.shouldCloseSidebar.emit();
      await this.router.navigate(['/crm/contacts/lists'], { queryParamsHandling: 'preserve' });
    }
  }

  getSortedFolderItemsWithRouterLinks(folder: Folder<Source | Segment>): Observable<Array<(Source | Segment) & { routerLink: any[] }>> {
    return this.store.select(selectSelectedTabInfo).pipe(
      map((optTabInfo) =>
        new OrderByKeyPipe().transform(
          folder.items.map((item: Source | Segment) => {
            const base: string = item['displayName'] ? item['template'] ? '/newsletters' : '/web-personalisation' : '/segments';
            const routerLink = [base, item.id].concat(item['displayName'] ? (optTabInfo || []) : []);
            return {
              ...item,
              routerLink
            };
          }),
          this.getOrderByKey(folder.items)
        )
      )
    );
  }

  goToTab(tabIndex: number) {
    this.sidenavTabs$$.next(
      this.sidenavTabs$$.getValue().slice(0, tabIndex)
    );
    this.selectedTabIndex$$.next(tabIndex);
  }

  removeAllExtraTabs() {
    if (this.nbOfTabs > 0) {
      this.sidenavTabs$$.next([]);
      this.selectedTabIndex$$.next(0);
    }
  }

  private getOrderByKey(items: any[] = []) {
    if (items.length === 0) {
      return undefined;
    }

    if (items[0].hasOwnProperty('displayName')) {
      return 'displayName';
    } else if (items[0].hasOwnProperty('displayName')) {
      return 'name';
    } else {
      return undefined;
    }
  }

  async openDialog(type: 'web' | 'email') {

    const engineId = await this.appService.getSelectedEngine().then((engine) => engine?.id);

    if (type === 'email') {
      this.addNewsletterForm = this._fb.group({
        'name': ['', Validators.required],
        'duplicationId': [''],
        'fromEngineId': [engineId]
      });

      const engines: Engine[] = await firstValueFrom(this.appService.engines$);
      this.engineListForDuplication = engines.map((engine) => [engine.displayName, engine.id]);

      await this.updateSourceListForDuplication(type, engineId)
    } else {
      this.addWebSourceForm = this._fb.group({
        'name': ['', Validators.required]
      })
    }

    const dialogRef = this.dialog.open(
      type === 'web' ? this.addWebSourceDialogTemplateRef : this.addNewsletterDialogTemplateRef,
      { panelClass: 'mediego-dialog' }
    );

    dialogRef.afterClosed().subscribe(async(result) => {

      if (type === 'web' && result) {
        await this.addSource(type, result);
      } else if (type === 'email' && result?.name) {
        if (result.duplicationId) {
          await this.duplicateSource(result.name, result.fromEngineId, result.duplicationId);
        } else {
          await this.addSource(type, result.name);
        }
      }

      this.duplicateEmailSourceSelectShown = false;
    });
  }

  enableDuplicateField() {
    this.duplicateEmailSourceSelectShown = true;
  }

  async updateSourceListForDuplication(type: 'web' | 'email', engineId: number): Promise<void> {
    const newsletters: NewsletterSource[] = await this.sourceService.getSources(engineId);
    this.newsletterListForDuplication = newsletters
    .filter((source) => source.sourceType === type)
    .sort((s1, s2) => s1.displayName.localeCompare(s2.displayName))
    .map((source) => [source.displayName, source.id]);
  }

  moveToNewFolderDialog(source: Source): Promise<string> {
    const dialog$ = this.dialog.open(
      this.moveSourceDialogTemplateRef,
      { panelClass: 'mediego-dialog' }
    ).afterClosed();

    return firstValueFrom(dialog$).then((result) => {
      if (result) {
        return result;
      }
    });
  }

  toggleDragDrop() {
    this.canMoveFolder = !this.canMoveFolder;
  }

  dragStart(event: CdkDragStart) {
    this.isDraggingMode = true;
    if (event && event.source && event.source.data) {
      this.newsletterDragged = event.source.data;
    }
  }

  dragEnd(event: CdkDragEnd) {
    this.isDraggingMode = false;
    this.folderHovered = null;
    this.newsletterDragged = null;
  }

  dropInNewFolder() {
    // this function is a fix, proxy to dropInFolder as New Folder seems buggy
    if (this.newsletterDragged) {
      this.dropInFolder(undefined, this.newEmptyFolder);
      this.dragEnd(undefined);
    }
  }

  async dropInFolder(event: CdkDragDrop<any>, folder: Folder) {

    if (this.newsletterDragged || (event && event.item && event.item.data)) {

      const itemDropped: Source = this.newsletterDragged || event.item.data;

      if (isNewsletter(itemDropped)) {

        let folderNamePromise: Promise<string>;
        if (folder === this.newEmptyFolder) {
          folderNamePromise = this.moveToNewFolderDialog(itemDropped).then((name) => {

            // creating folder
            this.store.dispatch(new AddEmailSourceFolder({
              folderPath: [name]
            }));

            return name;
          });
        } else {
          folderNamePromise = new Promise<string>((resolve, reject) => {
            resolve(folder.name);
          });
        }

        const name = await folderNamePromise;

        if (name) {

          itemDropped.collection.push(name);

          const engine = await this.appService.getSelectedEngine();

          // update database
          if (engine && engine.sources && engine.sources.newsletters_all) {

            const latestSourceIndex: number = engine.sources.newsletters_all.findIndex(newsletter => newsletter.id === itemDropped.id);
            const latestSource: Source = engine.sources.newsletters_all[latestSourceIndex];

            await this.sourceService.createOrUpdateSource(engine.id, {
              ...latestSource,
              collection: [...itemDropped.collection]
            }, 'updateFolder');

            if (folder !== this.newEmptyFolder) {
              await this.selectFolder({
                ...folder,
                items: [...folder.items, itemDropped]
              });
            } else {
              // component will refresh
            }
          }
        }
      }
    }
  }

  dropPreviewInFolder(event: CdkDragEnter, folder: Folder) {
    this.folderHovered = folder;
  }

  dropPreviewOutFolder(event: CdkDragExit, folder: Folder) {
    if (this.folderHovered === folder) {
      this.folderHovered = null;
    }
  }

  folderTrackingFunction(index: number, folder: Folder) {
    return index;
  }

  itemTrackingFunction(index: number, item: any) {
    if (item) {
      return item.id;
    } else {
      return item;
    }
  }

  private async duplicateSource(displayName: string, fromEngineId: number, sourceId: string): Promise<void> {
    try {
      const engine = await this.appService.getSelectedEngine();
      const source = await this.sourceService.duplicateSource(engine.id, displayName, fromEngineId, sourceId);

      const newsPerms: NewsletterPermissions = {
        newsletterId: source.id,
        configuration: true,
        statistics: true,
        editTemplate: true,
        editContent: true,
        editVariables: true,
        editCampaigns: true
      };

      this.store.dispatch(new InsertNewsletterPermissionsInSelectedEngineAction({ newsletterPermissions: newsPerms }));

      await this.router.navigate([AuthUtils.getDefaultRouteOfNewsletter(source, newsPerms)], { queryParamsHandling: 'preserve' });
      this.shouldCloseSidebar.emit();

    } catch (e) {
      console.error(e);
      this.appService.raiseError(new Error('Impossible de dupliquer la newsletter'));
    }
  }

  private async addSource(sourceType: 'web' | 'email', displayName: string): Promise<void> {
    try {
      const engine = await this.appService.getSelectedEngine();
      let sourceTemplate: Source = { sourceType, displayName, collection: [] };
      if (sourceType === 'email') {
        sourceTemplate = { ...sourceTemplate, enableTemplateEditor: true, enableAuthorReset: true } as NewsletterSource
      }
      const source = await this.sourceService.createOrUpdateSource(engine.id, sourceTemplate, 'create');

      if (sourceType === 'email') {
        const newsPerms: NewsletterPermissions = {
          newsletterId: source.id,
          configuration: true,
          statistics: true,
          editTemplate: true,
          editContent: true,
          editVariables: true,
          editCampaigns: true
        };
        this.store.dispatch(new InsertNewsletterPermissionsInSelectedEngineAction({ newsletterPermissions: newsPerms }));
        await this.router.navigate([AuthUtils.getDefaultRouteOfNewsletter(source, newsPerms)], { queryParamsHandling: 'preserve' });
      } else {
        await this.router.navigate(['web-personalisation', source.id, 'setup'], { queryParamsHandling: 'preserve' });
      }

      this.shouldCloseSidebar.emit();
    } catch (e) {
      console.error(e);
      this.appService.raiseError(
        new Error(
          this.translate.instant(sourceType === 'web' ?
            'COMMON.ERROR.WEB_SOURCE.ADDING.MESSAGE' : 'COMMON.ERROR.NEWSLETTER.ADDING.MESSAGE'
          )
        )
      );
    }
  }


}
