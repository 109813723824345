import { Component, HostBinding, OnInit, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators }                from '@angular/forms';
import { ActivatedRoute, Router }                            from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';

import { TranslateService } from '@ngx-translate/core';

import { AuthService }                                       from '../../../services/auth.service';

import { MediegoValidators }                                 from '../../../mediego-common-module/validators/mediego-validators';

@Component({
    selector: 'app-mediego-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    standalone: false
})
export class ForgotPasswordComponent implements OnInit {
  @HostBinding('class') classes = 'mainContent fullScreenMobile';

  initStatus: 'loading' | 'done' | 'error' = 'loading';
  isLoading: boolean;
  disableSubmit: boolean;

  passwordVisible = false;

  requestResetForm: UntypedFormGroup;
  resetPasswordForm: UntypedFormGroup;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private _fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private _vcr: ViewContainerRef,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    const token = this.route.snapshot.paramMap.get('token');

    if (token) {
      this.verifyToken(token)
        .then((email: string) => {
          this.resetPasswordForm = this._fb.group({
            token: [token, Validators.required],
            email: [{ value: email, disabled: true }, Validators.compose([
              Validators.required,
              Validators.email
            ])],
            password: ['', Validators.compose([
              Validators.required,
              MediegoValidators.sufficientlyStrongPassword()
            ])],
            confirmPassword: ['', Validators.compose([
              Validators.required,
              MediegoValidators.equalsControl('password')
            ])]
          });
          this.initStatus = 'done';
        })
        .catch((error) => {
          console.error(error);
          this.initStatus = 'error';
          let errorId = 'UNKNOWN';
          try { errorId = JSON.parse(error?.error).errorId; } catch (e) { }
          this.showSnackBarError(this.translateService.instant('MAIN.FORGOT_PASSWORD.RESET.ERRORS.' + errorId));
        });
    } else {
      this.requestResetForm = this._fb.group({
        email: ['', Validators.compose([
          Validators.required,
          Validators.email
        ])]
      });
      this.initStatus = 'done';
    }
  }

  requestReset(): void {
    if (this.requestResetForm.valid) {
      this.isLoading = true;
      this.authService
        .requestPasswordReset(this.requestResetForm.get('email').value)
        .then(() => {
          this.isLoading = false;
          this.showSnackBarSuccess(this.translateService.instant('MAIN.FORGOT_PASSWORD.RESET.SUCCESS'));
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.showSnackBarError(error.error || 'Impossible de demander la réinitialisation');
        });
    }
  }

  verifyToken(token: string): Promise<string> {
    return this.authService
      .verifyToken(token);
  }

  setNewPassword(): void {
    if (this.resetPasswordForm.valid) {
      this.isLoading = true;
      this.authService
        .setNewPassword(
          this.resetPasswordForm.get('token').value,
          this.resetPasswordForm.get('password').value
        )
        .then(() => {
          this.isLoading = false;
          this.disableSubmit = true;
          this.resetPasswordForm.get('password').disable();
          this.resetPasswordForm.get('confirmPassword').disable();

          const redirection = setTimeout(() => {
            this.router.navigate(['/login']);
          }, 3000);

          this.showSnackBarSuccess(
            this.translateService.instant('MAIN.FORGOT_PASSWORD.RESET.REDIRECT'),
            this.translateService.instant('MAIN.LOGIN.TITLE.A'),
            () => {
              if (redirection) clearTimeout(redirection);
              this.router.navigate(['/login']);
            });


        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.showSnackBarError(error.error || 'Impossible de demander la réinitialisation');
        });
    }
  }

  showSnackBarSuccess(message: string, action?: string, callback?: () => void) {
    const snackBarRef = this.snackBar.open(
      message,
      action,
      {
        panelClass: ['mediego-snack-bar', 'accent'],
        viewContainerRef: this._vcr
      }
    );

    if (action && callback) {
      snackBarRef.onAction().subscribe(callback);
    }
  }

  showSnackBarError(error_message?: string) {
    this.snackBar.open(
      error_message || 'Une erreur est survenue',
      undefined,
      {
        panelClass: ['mediego-snack-bar', 'warn'],
        viewContainerRef: this._vcr
      }
    );
  }

}
