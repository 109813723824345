import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { AuthService } from '../../../services/auth.service';
import { NotificationsService } from '../../../services/notifications.service';

import { MediegoMessage } from '../../../providers/misc.provider';


@Component({
    selector: 'app-mediego-notifications-card',
    templateUrl: './notifications-card.component.html',
    styleUrls: ['./notifications-card.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NotificationsCardComponent implements OnInit, OnDestroy {

  notifications: MediegoMessage[] = [];

  private unsubscribe$ = new Subject<void>();

  get isAdmin$() {
    return this.authService.isAdmin$;
  }

  constructor(private notificationsService: NotificationsService, private authService: AuthService) {}

  async ngOnInit() {

    this.notificationsService.notifications$.pipe(
      map((notifications) => {
        this.notifications = notifications || [];
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe();

    // await this.notificationsService.getMediegoMessages();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  dismissNotification(notification: MediegoMessage) {
    if (notification.pending) this.notificationsService.dismissNotification(notification.id);
  }

  dismissAllNotifications() {
    this.notificationsService.dismissAllNotifications();
  }


}
