import { Component, HostBinding, ViewEncapsulation } from '@angular/core';


@Component({
    selector: 'app-mediego-platform-error',
    templateUrl: './platform-error.component.html',
    styleUrls: ['./platform-error.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PlatformErrorComponent {
  @HostBinding('class') classes = 'mainContent';


  openSupport() {
    (window as any).Intercom('show');
  }
}
