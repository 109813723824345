import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { firstValueFrom, interval, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { AuthService } from '../../../services/auth.service';
import { NotificationsService } from '../../../services/notifications.service';

import { MediegoMessage } from '../../../providers/misc.provider';

@Component({
    selector: 'app-mediego-backboard',
    templateUrl: './backboard.component.html',
    styleUrls: ['./backboard.component.scss'],
    standalone: false
})
export class BackboardComponent implements OnInit, OnDestroy {

  @ViewChild('dialogRef', { static: true }) dialogRef;

  mediegoMessages: MediegoMessage[] = [];

  latestMessageShown: string = undefined;

  get latestMediegoMessage(): MediegoMessage {
    if (this.mediegoMessages?.length) return this.mediegoMessages[0];
  }

  private unsubscribe$ = new Subject<void>();

  constructor(private authService: AuthService, private dialog: MatDialog, private notificationsService: NotificationsService) {}

  async ngOnInit() {

    this.authService.authState$.pipe(
      filter(auth => auth.isLoggedIn),
      map(async() => {
        await this.fetchAndDisplayMessageIfRequired();
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe();

    // each 30 minutes, we try to fetch new messages
    interval(1000 * 60 * 30).pipe(
      map(async() => {
        await this.fetchAndDisplayMessageIfRequired();
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe();

    this.notificationsService.notifications$.pipe(
      map((notifications) => {
        this.mediegoMessages = notifications;
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe();

  }

  private async fetchAndDisplayMessageIfRequired() {
    await this.notificationsService.getMediegoMessages();

    // if we have a pending message and it has never been shown before for current session
    if (this.latestMediegoMessage?.pending
      && (!this.latestMessageShown || this.latestMessageShown !== this.latestMediegoMessage.id)) {

      // ensuring message is still valid

      const dateTimestamp = +new Date(this.latestMediegoMessage.publicationDate);
      const expirationDateTimestamp = dateTimestamp + (this.latestMediegoMessage.validity * 60 * 60 * 1000);
      const nowTimestamp = +Date.now();

      if (nowTimestamp > expirationDateTimestamp) return;

      this.latestMessageShown = this.latestMediegoMessage.id;

      const dialogClose$ = this.dialog.open(this.dialogRef, {
        panelClass: 'mediego-dialog',
        maxWidth: '800px',
        autoFocus: false
      }).afterClosed();

      firstValueFrom(dialogClose$).then((validation) => {
        if (validation) this.notificationsService.dismissNotification(this.latestMediegoMessage.id);
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
